const InpatientDataDictionary = [{
        fieldName: "providerCcn (oscarNumber)",
        dataType:"String",
        definition: [
            "Alpha-numeric 6-13 character provider number. Also known as Oscar Number."
        ]
    },
    {
        fieldName: "effectiveDate",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. This is the effective date of the provider's first PPS period, or for " +
            "subsequent PPS periods, the effective date of a change to the PROV file. If a termination date is " +
            "present for this record, the effective date must be equal to or less than the termination date. " +
            "Year: Greater than 82, but not greater than current year. " +
            "Month: 01-12 " +
            "Day: 01-31. "
        ]
    },
    {
        fieldName: "fiscalYearBeginDate (fiscalYearBeginningDate)",
        dataType:"String",
        definition: [
            "Must be updated annually to show the current year for providers receiving a blended payment " +
            "based on their FY begin date. Must be equal to or less than the effective date."
        ]
    },
    {
        fieldName: "exportDate (reportDate)",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. Date file created/run date of the PROV report for submittal to CMS CO."
        ]
    },
    {
        fieldName: "terminationDate",
        dataType:"String",
        definition: [
            "Termination Date in this context is the date on which the reporting MAC ceased servicing the provider. " +
            "Must be zeroes or contain a termination date. Must be equal to or greater than the effective date. If " +
            "the provider is terminated or transferred to another MAC, a termination date is placed in the file to " +
            "reflect the last date the provider was serviced by the outgoing MAC."
        ]
    },
    {
        fieldName: "waiverIndicator",
        dataType:"String",
        definition: [
            "Y = waived (Provider is not under PPS).",
            "N = not waived (Provider is under PPS)."
        ]
    },
    {
        fieldName: "intermediaryNumber",
        dataType:"String",
        definition: [
            "Assigned intermediary number of the Medicare Administrative Contractor (MAC)."
        ]
    },
    {
        fieldName: "providerType",
        dataType:"String",
        definition: [
            "This identifies the inpatient provider type, such as short term facility, long-term, psychiatric, " +
            "rehabilitation facility, and so on."
        ]
    },
    {
        fieldName: "censusDivision",
        dataType:"String",
        definition: [
            "The Census division to which the facility belongs for payment purposes. When a facility is " +
            "reclassified for the standardized amount, MACs must change the census division to reflect the new " +
            "standardized amount location."
        ]
    },
    {
        fieldName: "msaActualGeographicLocation (actualGeographicLocation_MSA)",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the MSA 0040-9965, or the rural area, (blank) " +
            "(blank) 2 digit numeric State code such as _ _36 for Ohio, where the facility is physically located. "
        ]
    },
    {
        fieldName: "msaWageIndexLocation (wageIndexLocation_MSA)",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the MSA, 0040-9965, or the rural area, (blank) (blank) (2 digit numeric " + 
            "State code) such as _ _ 3 6 for Ohio, to which a hospital has been reclassified due to its prevailing " +
            "wage rates. Leave blank or enter the actual location MSA (field 13), if not reclassified. Pricer will " +
            "automatically default to the actual location MSA if this field is left blank."
        ]
    },
    {
        fieldName: "msaStandardizedAmountLocation (standardizedAmountLocation_MSA)",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the MSA, 0040-9965, or the rural area, (blank) (blank) (2 digit numeric " +
            "State code) such as _ _ 3 6 for Ohio, to which a hospital has been reclassified for standardized " +
            "amount. Leave blank or enter the actual location MSA (field 13) if not reclassified. Pricer will " +
            "automatically default to the actual location MSA if this field is left blank."
        ]
    },
    {
        fieldName: "soleCommunityOrMedicareDependentHospitalBaseYear",
        dataType:"String",
        definition: [
            "Leave blank if not a sole community hospital (SCH) or a Medicare dependent hospital (MDH) effective " +
            "with cost reporting periods that begin on or after April 1, 1990. If an SCH or an MDH, show the base " +
            "year for the operating hospital specific rate, the higher of either 82 or 87. See §20.6. Must be " +
            "completed for any SCH or MDH that operated in 82 or 87, even if the hospital will be paid at the " +
            "Federal rate. Eff. 10/1/12, MDHs are no longer valid provider types."
        ]
    },
    {
        fieldName: "changeCodeForLugarReclassification",
        dataType:"String",
        definition: [
            "Will contain an \"L\" if the MSA has been reclassified for wage index purposes under §1886(d)(8)(B) of " +
            "the Act. These are also known as Lugar reclassifications, and apply to ASC-approved services provided " +
            "on an outpatient basis when a hospital qualifies for payment under an alternate wage index MSA. Leave " +
            "blank for hospitals if there has not been a Lugar reclassification."
        ]
    },
    {
        fieldName: "temporaryReliefIndicator",
        dataType:"String",
        definition: [
            "Enter a “Y” if this provider qualifies for a payment update under the temporary relief provision, " +
            "otherwise leave blank.\n"
        ]
    },
    {
        fieldName: "federalPpsBlend",
        dataType:"String",
        definition: [
            "HH PPS: For “From” dates before 1/1/2021: the value to indicate if normal percentage payments should " +
            "be made on RAP and/or whether payment should be reduced under the Quality Reporting Program. Valid " +
            "values: 0 = Make normal percentage payment; 1 = Pay 0%; 2 = Make final payment reduced by 2%; 3 = " +
            "Make final payment reduced by 2%. pay RAPs at 0%"
        ]
    },
    {
        fieldName: "stateCode (state)",
        dataType:"String",
        definition: [
            "Enter the 2-digit state where the provider is located. Enter only the first (lowest) code for a " +
            "given state. For example, effective October 1, 2005, Florida has the following State Codes: 10, " +
            "68 and 69. MACs shall enter a “10” for Florida’s state code.List of valid state codes is located " +
            "in Pub. 100-07, Chapter 2, Section 2779A1."
        ]
    },
    {
        fieldName: "caseMixAdjustedCostPerDischarge_PpsFacilitySpecificRate",
        dataType:"Number",
        definition: [
            "For PPS hospitals and waiver state non-excluded hospitals, the base year cost per discharge divided by " +
            "the case mix index. Will have zero for new providers."
        ]
    },
    {
        fieldName: "costOfLivingAdjustment",
        dataType:"Number",
        definition: [
            "The COLA adjustment. All hospitals except Alaska and Hawaii use 1.000."
        ]
    },
    {
        fieldName: "internsToBedsRatio",
        dataType:"Number",
        definition: [
            "This is the provider's intern/resident to bed ratio. Calculated by dividing the provider's " +
            "full time equivalent residents by the number of available beds. Enter zero for non-teaching hospitals."
        ]
    },
    {
        fieldName: "bedSize",
        dataType:"Number",
        definition: [
            "Enter the number of adult hospital beds and pediatric beds available for lodging inpatient. Must be " +
            "greater than zero."
        ]
    },
    {
        fieldName: "operatingCostToChargeRatio",
        dataType:"Number",
        definition: [
            "Derived by MACs from the latest settled cost report and corresponding charge data from the billing " +
            "file. Computed amount by dividing the Medicare operating costs by Medicare covered charges."
        ]
    },
    {
        fieldName: "caseMixIndex",
        dataType:"Number",
        definition: [
            "The case mix index is used to compute positions field 18. Zero-fill for all others."
        ]
    },
    {
        fieldName: "supplementalSecurityIncomeRatio",
        dataType:"Number",
        definition: [
            "The SSI ratio used to determine if the hospital qualifies for a disproportionate share adjustment " +
            "and to determine the size of the capital and operating DSH adjustments."
        ]
    },
    {
        fieldName: "medicaidRatio",
        dataType:"Number",
        definition: [
            "The Medicaid ratio used to determine if the hospital qualifies for a disproportionate share " +
            "adjustment and to determine the size of the capital and operating DSH adjustments."
        ]
    },
    {
        fieldName: "specialProviderUpdateFactor",
        dataType:"Number",
        definition: [
            "Zero-fill for all hospitals after FY91. This Field is obsolete for hospitals as of FY92. " +
            "Effective 1/1/2018, this field is used for HHAs only. Enter the HH VBP adjustment factor " +
            "provided by CMS for each HHA. If no factor is provided, enter 1.00000."
        ]
    },
    {
        fieldName: "operatingDsh",
        dataType:"Number",
        definition: [
            "Disproportionate share adjustment Percentage. Pricer calculates the Operating DSH effective 10/1/91 " +
            "and bypasses this field. Zero-fill for all hospitals 10/1/91 and later."
        ]
    },
    {
        fieldName: "fiscalYearEndDate (fiscalYearEnd)",
        dataType:"String",
        definition: [
            "This field is no longer used. If present, must be CCYYMMDD."
        ]
    },
    {
        fieldName: "specialPaymentIndicator",
        dataType:"String",
        definition: [
            "The code that indicates the type of special payment provision that applies.",
            "Blank = not applicable",
            "Y = reclassified",
            "1 = special wage index indicator",
            "2 = both special wage index indicator and reclassified",
            "D = Dual reclassified"
        ]
    },
    {
        fieldName: "hospitalQualityIndicator",
        dataType:"String",
        definition: [
            "Code to indicate that hospital meets criteria to receive higher payment per MMA quality standards. " +
            "Blank = hospital does not meet criteria' 1 = hospital quality standards have been met"
        ]
    },
    {
        fieldName: "cbsaActualGeographicLocation (actualGeographicLocation_CBSA)",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the CBSA 00001-89999, or the rural area, (blank (blank) (blank) 2 " +
            "digit numeric State code such as _ _ _ 36 for Ohio, where the facility is physically located. "
        ]
    },
    {
        fieldName: "cbsaWageIndexLocation (wageIndexLocation_CBSA)",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the CBSA, 00001-89999, or the rural area, (blank)(blank) (blank) (2 digit " +
            "numeric State code) such as _ _ _ 3 6 for Ohio, to which a hospital has been reclassified due to its " +
            "prevailing wage rates. Leave blank or enter the actual location CBSA (field 35), if not reclassified. " +
            "Pricer will automatically default to the actual location CBSA if this field is left blank. "
        ]
    },
    {
        fieldName: "cbsaStandardizedAmountLocation (standardizedAmountLocation_CBSA)",
        dataType:"String",
        definition: [
            "The appropriate code for the CBSA, 00001-89999 or the rural area, (blank) (blank)(blank) (2 digit " +
            "numeric State code) such as _ _ _ 3 6 for Ohio, to which a hospital has been reclassified. Leave " +
            "blank or enter the actual location CBSA (31) if not reclassified. Pricer will automatically default " +
            "to the actual location CBSA if this field is left blank."
        ]
    },
    {
        fieldName: "specialWageIndex",
        dataType:"Number",
        definition: [
            "Enter the special wage index that certain providers may be assigned. Enter zeroes unless the Special " +
            "Payment Indicator field equals a “1” or “2.”"
        ]
    },
    {
        fieldName: "passThroughAmountForCapital",
        dataType:"Number",
        definition: [
            "Per diem amount based on the interim payments to the hospital. Zero-fill if this does not apply."
        ]
    },
    {
        fieldName: "passThroughAmountForDirectMedicalEducation",
        dataType:"Number",
        definition: [
            "Per diem amount based on the interim payments to the hospital Zerofill if this does not apply."
        ]
    },
    {
        fieldName: "passThroughAmountForOrganAcquisition",
        dataType:"Number",
        definition: [
            "Per diem amount based on the interim payments to the hospital. Zerofill if this does not apply."
        ]
    },
    {
        fieldName: "passThroughTotalAmount (totalPassThroughAmount)",
        dataType:"Number",
        definition: [
            "Per diem amount based on the interim payments to the hospital. Zerofill if this does not apply."
        ]
    },
    {
        fieldName: "capitalPpsPaymentCode",
        dataType:"String",
        definition: [
            "Enter the code to indicate the type of capital payment methodology for hospitals: A = Hold Harmless – " +
            "cost payment for old  capital; B = Hold Harmless – 100% Federal rate; C = Fully prospective blended rate."
        ]
    },
    {
        fieldName: "hospitalSpecificCapitalRate",
        dataType:"Number",
        definition: [
            "Must be present unless: • A \"Y\" is entered in the Capital Indirect Medical Education Ratio field; or",
            "A“08” is entered in the Provider Type field; or",
            "A termination date is present in Termination Date field.",
            "Enter the hospital's allowable adjusted base year inpatient capital costs per discharge.",
            "This field is not used as of 10/1/02."
        ]
    },
    {
        fieldName: "oldCapitalHoldHarmlessRate",
        dataType:"Number",
        definition: [
            "Enter the hospital's allowable inpatient \"old\" capital costs per discharge incurred for assets " +
            "acquired before December 31, 1990, for capital PPS. Update annually."
        ]
    },
    {
        fieldName: "newCapitalHoldHarmlessRate",
        dataType:"Number",
        definition: [
            "Enter the ratio of the hospital's allowable inpatient costs for new capital to the hospital's " +
            "total allowable inpatient capital costs. Update annually."
        ]
    },
    {
        fieldName: "capitalCostToChargeRatio",
        dataType:"Number",
        definition: [
            "Derived from the latest cost report and corresponding charge data from the billing file. For " +
            "hospitals for which the MAC is unable to compute a reasonable cost-to-charge ratio, it uses the " +
            "appropriate statewide average cost-to-charge ratio calculated annually by CMS and published in " +
            "the \"Federal Register.\""
        ]
    },
    {
        fieldName: "newHospital",
        dataType:"String",
        definition: [
            "Enter \"Y\" for the first 2 years that a new hospital is in operation. Leave blank if hospital is not " +
            "within first 2 years of operation."
        ]
    },
    {
        fieldName: "capitalIndirectMedicalEducationRatio",
        dataType:"Number",
        definition: [
            "This is for IPPS hospitals and IRFs only. Enter the ratio of residents/interns to the hospital's " +
            "average daily census. Calculate by dividing the hospital's full-time equivalent total of residents " +
            "during the fiscal year by the hospital's total inpatient days. Zero-fill for a non-teaching hospital."
        ]
    },
    {
        fieldName: "capitalExceptionPaymentRate",
        dataType:"Number",
        definition: [
            "The per discharge exception payment to which a hospital is entitled."
        ]
    },
    {
        fieldName: "vpbParticipantIndicator (valueBasedPurchasingParticipant)",
        dataType:"String",
        definition: [
            "Enter “Y” if participating in Hospital Value Based Purchasing. Enter “N” if not participating. Note " +
            "if Data Element AD (Hospital Quality Ind) is blank, then this field must = N."
        ]
    },
    {
        fieldName: "vbpAdjustment (valueBasedPurchasingAdjustment)",
        dataType:"Number",
        definition: [
            "Enter VBP Adjustment Factor. If prior element (vpbParticipantIndicator) is blank, leave blank."
        ]
    },
    {
        fieldName: "hhrParticipantIndicator (hospitalReadmissionsReductionProgramParticipant)",
        dataType:"Number",
        definition: [
            "Enter “0” if not participating in Hospital Re-admissions Reduction program.",
            "Enter “1” if participating in Hospital Re-admissions Reduction program and payment adjustment " +
            "is not 1.0000.",
            "Enter “2” if participating in Hospital Re-admissions Reduction program and payment adjustment is equal " +
            "to 1.0000."
        ]
    },
    {
        fieldName: "hhrAdjustment (hospitalReadmissionsReductionAdjustment)",
        dataType:"Number",
        definition: [
            "Enter HRR Adjustment Factor if “1” is entered in prior element (hhrParticipantIndicator). Leave blank if “0” or “2” is " +
            "entered in AW Participant indicator."
        ]
    },
    {
        fieldName: "bundleModel1Discount",
        dataType:"Number",
        definition: [
            "The discount % for hospitals participating in Bundled Payments for Care Improvement " +
            "Initiative (BPCI), Model 1 (demo code 61)."
        ]
    },
    {
        fieldName: "hacReductionParticipantIndicator (hospitalAcquiredConditionReductionProgramParticipant)",
        dataType:"String",
        definition: [
            "Enter a ‘Y’ if the hospital is subject to a reduction under the HAC Reduction Program. Enter a ‘N’ " +
            "if the hospital is NOT subject to a reduction under the HAC Reduction Program."
        ]
    },
    {
        fieldName: "uncompensatedCareAmount",
        dataType:"Number",
        definition: [
            "Enter the estimated per discharge uncompensated care payment amount calculated and published by CMS " +
            "for each hospital."
        ]
    },
    {
        fieldName: "ehrReductionIndicator (electronicHealthRecordsProgramReduction)",
        dataType:"String",
        definition: [
            "Enter a ‘Y’ if the hospital is subject to a reduction due to NOT being an EHR meaningful user. Leave " +
            "blank if the hospital is an Electronic Health Records meaningful user."
        ]
    },
    {
        fieldName: "lowVolumeAdjustmentFactor",
        dataType:"Number",
        definition: [
            "Enter the low-volume hospital payment adjustment factor calculated and published by the Centers " +
            "for Medicare & Medicaid Services (CMS) for each eligible hospital."
        ]
    },
    {
        fieldName: "countyCode",
        dataType:"String",
        definition: [
            "Enter the County Code. Must be 5 numbers."
        ]
    },
    {
        fieldName: "medicarePerformanceAdjustment",
        dataType:"Number",
        definition: [
            "Enter the MPA percentage calculated and published by the Centers for Medicare & Medicaid Services (CMS)."
        ]
    },
    {
        fieldName: "ltchDppIndicator",
        dataType:"String",
        definition: [
            "Enter a ‘Y’ if the LTCH is subject to the DPP payment adjustment. Leave blank if the LTCH is not " +
            "subject to the DPP payment adjustment."
        ]
    },
    {
        fieldName: "supplementalWageIndex",
        dataType:"Number",
        definition: [
            "Enter the supplemental wage index that certain providers may be assigned. Enter zeroes if it does not apply."
        ]
    },
    {
        fieldName: "supplementalWageIndexIndicator (supplementalWageIndexFlag)",
        dataType:"String",
        definition: [
            "Enter the supplemental wage index indicator that certain providers may be assigned: 1=Prior Year Wage " +
            "Index*; 2=Future use; 3=Future use; Enter blank if it does not apply",
            "Note: For LTCH’s providers this is the IPPS prior year wage index."
        ]
    },
    {
        fieldName: "changeCodeWageIndexReclassification",
        dataType:"String",
        definition: [
            "Enter \"Y\" if hospital's wage index location has been reclassified for the year. Enter \"N\" if it " +
            "has not been reclassified for the year. Adjust annually."
        ]
    },
    {
        fieldName: "nationalProviderIdentifier",
        dataType:"String",
        definition: [
            "Alpha-numeric 10 character NPI number."
        ]
    },
    {
        fieldName: "passThroughAmountForAllogenicStemCellAcquisition",
        dataType:"Number",
        definition: [
            "The per diem payment amount based on the interim payments to the hospital that includes acquisition amounts for allogeneic stem cell. The pass-through amount is not included in total pass-through and miscellaneous field."
        ]
    },
        {
        fieldName: "ppsBlendYearIndicator",
        dataType:"String",
        definition: [
            "The year where there is a blend of payments from cost-based principles and the Federal Long Term Care Hospital (LTCH) PPS rate."
        ]
    },
    {
        fieldName: "lastUpdated",
        dataType:"String",
        definition: [
            "A date formatted as an ISO 8601 date string (YYYY-MM-DD), specifying the date that the provider record was last updated in the VSAM PSF file. This date is inclusive."
        ]
    },
    {
        fieldName: "passThroughAmountForDirectGraduateMedicalEducation",
        dataType:"Number",
        definition: [
            "Per diem amount of direct graduate medical education to be excluded from Medicare Advantage (MA) capitation rates per regulation."
        ]
    },
    {
        fieldName: "passThroughAmountForKidneyAcquisition",
        dataType:"Number",
        definition: [
            "Per diem amount of kidney acquisition costs to be excluded from MA capitation rates per regulation."
        ]
    },
      {
        fieldName: "passThroughAmountForN95",
        dataType:"Number",
        definition: [
            "Per diem amount based on the procurement of wholly domestically made NIOSH-approved surgical N95 respirators."
        ]
    }
]

export default InpatientDataDictionary;
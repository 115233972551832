/***
 * IMPORTANT: This is to be kept in sync with /rootDir/CHANGELOG.md until this process is more automated.
 *
 * @type {[{summary: string, date: string, releaseDetails: string[]}, {summary: string, date: string, releaseDetails: string[]}]}
 */

const ChangeLog = [  
		{
    summary: "Updated site to use PDS API v2.",
    date: "07.15.2024",
    releaseDetails: [
        "Updated Data Download page to use PDS API v2 endpoints.",
        "Updated Inpatient Data Dictionary to use PDS API v2 field names."
    ]
},
	{
    summary: "Updated pages to fix minor issues and 508 compliance changes.",
    date: "01.15.2024",
    releaseDetails: [
        "Fixed PDS UI Display issues in split-screen.",
        "Updated buttons for Effective dates to have unique button names to satisfy 508 Compliance.",
        "Added missing landmarks on Data Dictionary, Developer Documentation and Changelog Pages to ensure users can navigate to a section when using screen readers.",
        "Updated broken links throughout PDS UI.",
        "Data Type field values on Data Dictionary Page are now keyboard focusable."
    ]
},
{
    summary: "Updated all pages to fix minor issues and 508 compliance changes.",
    date: "10.16.2023",
    releaseDetails: [
        "Updated the heading levels according to the 508 Compliance.",
        "Updated the Inpatient and Outpatient Field Names descriptions on Data Dictionary Page.",
        "Added the 'Skip to main content' functionality to the separate Documentation pages."
    ]
},
{
    summary: "Updated outpatient output filtering for Outpatient ESRD providers.",
    date: "08.10.2023",
    releaseDetails: [
        "One of the rules for filtering outpatient data in API responses no longer applies to Outpatient ESRD providers."
    ]
},
{
    summary: "Added New Inpatient field.",
    date: "06.21.2023",
    releaseDetails: [
        "Added new field passThroughAmountForN95 to exports and inpatient data dictionary"
    ]
},{
    summary: "New Inpatient fields and PDS Site now allows users to download PSF Data on demand from CMS in near real-time.",
    date: "04.18.2023",
    releaseDetails: [
        "Updated API responses to show the current date for the report date and removed 'Latest data' date from UI.",
        "Added new fields passThroughAmountForDirectGraduateMedicalEducation and passThroughAmountForKidneyAcquisition to inpatient data dictionary",
        "Added missing descriptions for fields in inpatient data dictionary"
    ]
},{
    summary: "New fields added to Outpatient API responses and export files.",
    date: "02.15.2023",
    releaseDetails: [
        "Added new fields 'carrierCode' and 'localityCode' to the Outpatient API responses, and CSV and Excel export files.",
        "Added descriptions for the new fields 'carrierCode' and 'localityCode' to the Outpatient Data Dictionary.",
        "Updated Inpatient and Outpatient PSF Data. Data is now current through January 31, 2023."
    ]
},{
    summary: "New URL for PDS API and UI",
    date: "01.12.2023",
    releaseDetails: [
        "Added alert to notify users of the new URL for the Provider Data Service (PDS) UI and API.",
        "Updated  Inpatient and Outpatient PSF Data. Data is now current through December 31, 2022."
    ]
},{
    summary: "Q3 2022 PSF data",
    date: "10.24.2022",
    releaseDetails: [
        "This year's Q3 Inpatient and Outpatient PSF Data is now available through October 6, 2022",
        "Field soleCommunityOrMedicareDependentHospitalBaseYear has been changed from a number to a string",
        "Fixed issue regarding the Facility Type filter not clearing properly on the Data Download page"
    ]
},{
    summary: "Q2 2022 PSF data, New Filter Parameter to return Latest record only",
    date: "07.19.2022",
    releaseDetails: [
        "This year's Q2 Inpatient and Outpatients PSF Data is now available through June 30, 2022",
        "`latestRecordOnly` Query filter are now suited for CSV and Excel export requests and will return the most recent effective date record.",
        "The Data posted date has been removed from the Download Data Page."
    ]
},{
        summary: "Q1 2022 PSF data, Fixed assortment of issues on Data Download page ",
        date: "04.28.2022",
        releaseDetails: [
            "Updated Q1 2022 Inpatient and Outpatient PSF Data. Data is now current through March 31, 2022.",
            "Fixed issue on Data Download page where facility type filters should be disabled for the outpatient downloads",
            "Updated verbage for Effective Date filter warning and error messages",
            "Fixed issue regarding Effective Date filters where deleting the day would also delete the year",
            "Added logic to track page views in beta site."
        ]
    },
    {
        summary: "Beta Site fixes.",
        date: "02.28.2022",
        releaseDetails: [
            "Corrected spelling errors in beta site.",
        ]
    },
    {
        summary: "Fixes before beta site usability testing.",
        date: "02.22.2022",
        releaseDetails: [
            "Corrected font family issues in Beta Site.",
            "Corrected missing oscarNumber field in Inpatient Data Dictionary.",
            "Fixed issues for Beta Site Usability Testing. "
        ]
    },
    {
        summary: "Q4 2021 PSF data, Inpatient PSF data corrections, database optimization for exports, swagger documentation and API field name corrections.",
        date: "01.19.2022",
        releaseDetails: [
            "Updated Q4 2021 Inpatient and Outpatient PSF Data. Data is now current through December 31, 2021.",
            "Allow previously rejected Inpatient records with dates of '20000000' in the database.",
            "Corrected spelling of passThroughAmountForOrganAquisition field for Inpatient Provider response.",
            "Corrected definition of operatingCostToChargeRatio field in swagger documentation.",
            "Corrected 508 issues, font family issues in Beta Site"
        ]
    },
    {
        summary: "Initial Release of Beta Site",
        date: "11.30.2021",
        releaseDetails: [
            "Initial release of new beta website to enhance user experience with Provider Specific Data.",
            "Corrected Outpatient fields in Swagger to have the proper definitions"
        ]
    },
    {
        summary: "Q3 2021 PSF data, corrected identified issues with a few fields",
        date: "11.18.2021",
        releaseDetails: [
            "Updated Q3 2021 Inpatient and Outpatient PSF Data. Data is now current through October 18, 2021",
            "Corrected formatting issue with CBSA Wage Index Location field.",
            "Corrected name from Fiscal Year Beginning Date to Fiscal Year Begin Date for consistency"
        ]
    },
    {
        summary: "Q2 2021 Inpatient PSF data, new outpatient PSF API endpoints, name changes",
        date: "09.17.2021",
        releaseDetails: [
            "Updated Q2 2021 Inpatient PSF data. Data is now current through June 30, 2021.",
            "New outpatient PSF API endpoints to enable downloading historical outpatient data.",
            "Name changes in the Inpatient PSF for better human readability of data elements.",
            "Enhancements to the web site to address accessibility issues."
        ]
    },
    {
        summary: "New alpha website, Excel format, filters",
        date: "06.15.2021",
        releaseDetails: [
            "Launch of the new alpha website for downloading PSF data.",
            "Data downloads newly available in Excel format in addition to CSV.",
            "Changes filter name of 'Pricer Type' to 'Facility Type' for improved clarity.",
            "A new filter for downloading data by human-readable state abbreviations."
        ]
    }
]

export default ChangeLog;